.pt-2rem {
  padding-top: 2rem;
}
.mt-2rem {
  margin-top: 2rem;
}
.table-wrapper {
  max-height: 90%;
}
.table-header {
  padding: 1rem 0 !important;
}
.vh-60 {
  height: 60vh;
}
.width-2 {
  width: 2%;
}
.width-90 {
  width: 90%;
}
.width-92 {
  width: 92%;
}
.width-70 {
  width: 70%;
}
.folder-wrapper {
  border-radius: 50%;
  background-color: #fee6e7;
  padding: 10px;
}
.folder {
  width: 3rem;
}
/* .ant-select {
  margin-bottom: 0px !important;
} */
.team-row {
  border-bottom: 0.5px solid rgba(185, 185, 185, 0.3);
  color: #48484a;
}
.ball-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f3000b;
  display: block;
}
.check-bg {
  width: 56px;
  height: 56px;
  background-color: #fef4e6;
  border-radius: 50%;
}
.ball-bullet-black {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #1c1c1e;
  display: block;
}
.pr1rem {
  padding-right: 1rem;
}
.slide-in {
  animation: slide-in 0.3s ease-in-out;
}

@keyframes slide-in {
  0% {
    opacity: 0.3;
    margin-left: 10%;
  }
  50% {
    margin-left: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in-mobile {
  0% {
    opacity: 0;
  }
  50% {
    margin-left: 0;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 760px) {
  .mobile-v100 {
    height: calc(100vh - 280px);
  }
  .slide-in {
    animation: slide-in-mobile 0.3s ease-in-out;
  }
  /* reset width-70 */
  .width-70 {
    width: 100%;
  }
  .mobile-width-77 {
    width: 77%;
  }
}
