.webcam-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.webcam-img {
  border: 1px dotted #ae207c;
  height: 180px;
}

.webcam-btn {
  background: #ff1c26 !important;
  border: 0.5px solid rgba(25, 0, 65, 0.13);
  border-radius: 5px;
  padding: 10px 20px;
  width: 150px;
  cursor: pointer;
  color: #fff;
}
.video-info-wrapper {
  background-color: var(--color-page-background);
  min-height: 200px;
  padding: 2.5em;
}
.timer {
  top: 40%;
  left: 25%;
  color: var(--color-action-2);
  font-size: 1.2em;
  animation: zoom-in-zoom-out 1.1s ease infinite;
}
.secondary_timer {
  color: var(--color-action-2);
  font-size: 1.1rem;
  animation: zoom-in-zoom-out 1.1s ease infinite;
}
.sec-timer-bg {
  background-color: #e6e2c3;
  top: 87%;
  left: 44%;
  width: 2rem;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(0.9, 0.9);
  }
}
